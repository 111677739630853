import React, { useState } from "react"
import { PageProps } from "gatsby"
import classNames from "classnames"
import { useIntl } from "gatsby-plugin-intl"

import componentStyles from "./404.module.scss"
import Logo from "../assets/images/logo.svg"
import Button from "../components/Button/Button"
import SEO from "../components/seo"

const NotFoundPage: React.FC<PageProps> = ({ location }) => {
  const intl = useIntl()

  const notFoundClasses = classNames(componentStyles.notFound)
  const notFoundContainerClasses = classNames(componentStyles.notFoundContainer)
  const notFoundTitleClasses = classNames(componentStyles.notFoundTitle)

  return (
    <div className={notFoundClasses}>
      <SEO
        language={intl.locale}
        description=""
        title={intl.formatMessage({ id: "404.title" })}
        pathname={location.pathname}
      />
      <div className={notFoundContainerClasses}>
        <img
          className={componentStyles.logoImage}
          src={Logo}
          alt="Inventale logo"
          width="110"
          height="26"
        />
        <h1 className={notFoundTitleClasses}>
          {intl.formatMessage({ id: "404.title" })}
        </h1>
        <Button
          className={componentStyles.link}
          color="orange"
          filled={true}
          to="/"
        >
          {intl.formatMessage({ id: "404.button" })}
        </Button>
      </div>
    </div>
  )
}

export default NotFoundPage
